/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Transforms"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for transformations, use the ", _jsx(_components.code, {
        children: "theme.transforms"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n  export const theme = {\n    transforms: {\n+     'scale-xl': 'scale(2, 2)',\n    },\n  }\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
